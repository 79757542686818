import * as React from "react"
import analytics, { track, page} from './src/analytics';
import { ReactNode, useEffect, useRef } from 'react';
import type { GatsbyBrowser } from "gatsby"
import { Analytics } from 'analytics';

function Wrapper({ children }: React.PropsWithChildren<{}>){
  // Track first page interaction
  const trackedInteraciton = useRef(false);
  useEffect(() => {
    const handleInteraction = (event: MouseEvent | TouchEvent) => {
      if (!trackedInteraciton.current) {
        track('Page First Interaction', {
          path: window.location.pathname,
          search: window.location.search,
          hash: window.location.hash,
          eventType: event.type
        });
        trackedInteraciton.current = true;
      }
    }
    const events = [
      'mousemove',
      'click',
      'touchstart'
    ] as const;
    events.forEach((event) => {
      document.addEventListener(event, handleInteraction);
    });
    return () => {
      events.forEach((event) => {
        document.removeEventListener(event, handleInteraction);
      });
    };
  }, []);
  return children;
};

export const wrapRootElement = ({ element }) => {
  return <Wrapper>{element}</Wrapper>;
}
// On route update, set a timeout on page linger
// This keeps it in sync with https://github.com/DavidWells/analytics/blob/master/packages/gatsby-plugin-analytics/gatsby-browser.js
// which setups the page tracking
export const onRouteUpdate: GatsbyBrowser['onRouteUpdate'] = ({ location, prevLocation }, options) => {
  const initPath = location.pathname;
  setTimeout(() => {
    const currentPath = window.location.pathname;
    if (currentPath === initPath) {
      const pageDataProperties = analytics.getState('page')?.last?.properties ?? null;
      track('Page Linger', {
        ...pageDataProperties,
      });
    }
  }, 1000 * 20);

  // Only fire when the page actually changes
  if (location.pathname !== prevLocation?.pathname) {
    page()
  }
}