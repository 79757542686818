import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout';
import HeaderMeta from '../components/HeaderMeta';

import {
  BasicCard,
  BasicGrid,
  BodyBox,
  Button,
  ColorList,
  FlexColumn,
  FlexRow,
  HeadingBox,
  HomePageVennDiagram,
  LinkBox,
  MinwareOctopusStack,
  responsiveBreakpoints,
  ResponsiveComparison,
  ResponsiveComparisonParagraph,
  ResponsiveComparisonText,
  ResponsiveFigure,
  ResponsiveImage,
  ResponsiveMaxWidthFrame,
  ResponsiveTwoColumn,
  SimpleIcon,
  SectionGroup,
  SubHeadlineBox,
  TitleBox,
  vectors,
} from "@minware/ui-minware";

const {
  graphLink,
  valueStream,
  lineChart,
  listCheck,
  scorecardGraphic,
  githubLogo,
  gitlabLogo,
  azureDevopsLogo,
  bitbucketLogo,
  jiraLogo,
  googleCalendarLogo,
  trophy,
  book,
  check,
  historyCircle,
} = vectors;

const wideBreakpoint = 800;
const inputLogos = [
  {
    label: 'GitHub',
    vector: githubLogo,
  },
  {
    label: 'GitLab',
    vector: gitlabLogo,
  },
  {
    label: 'Azure',
    vector: azureDevopsLogo,
  },
  {
    label: 'Bitbucket',
    vector: bitbucketLogo,
  },
  {
    label: 'Jira',
    vector: jiraLogo,
  },
  {
    label: 'Google',
    vector: googleCalendarLogo,
  },
];

const stackItems = [
  {
    label: 'Data Quality',
    vector: check,
    color: ColorList.green100,
  },
  {
    label: 'Code/Ticket Linking',
    vector: graphLink,
    color: ColorList.cyan100,
  },
  {
    label: 'Change History',
    vector: historyCircle,
    color: ColorList.purple100,
  },
  {
    label: 'Work Timeline',
    vector: valueStream,
    color: ColorList.magenta100,
  },
];
const outputReports = [
  {
    label: 'Report Library',
    vector: book,
  },
  {
    label: 'Custom Reports',
    vector: lineChart,
  },
  {
    label: 'Scorecard Metrics',
    vector: listCheck,
  },
  {
    label: 'Benchmarks',
    vector: trophy,
  },
];

const octopusProps = {
  inputLogos,
  stackItems,
  outputReports,
  wideBreakpoint,
  bottomText: 'Data Modeling Platform',
};

export const IndexPageTemplate = ({
  header,
  title,
  subheading,
  subheading2,
  ctaText,
  ctaLink,
  secondCtaText,
  secondCtaLink,
  topQuote,
  mainSection,
  fundamentals,
  scorecardSection,
  impactSection,
  impact,
  octopus
}) => {
  octopus = octopus || {};

  const headerWithCanon = {
    ...header,
    canonicalUrl: '',
  };

  return (
    <Layout desktopBgImageName="homeDesktop">
      <HeaderMeta header={headerWithCanon}/>
      <ResponsiveMaxWidthFrame maxWidth="560px" spacing={20}>
        <SectionGroup>
          <TitleBox>{title}</TitleBox>
          <SubHeadlineBox centered isMain>
            {subheading}
          </SubHeadlineBox>
          <SubHeadlineBox centered isMain>
            {subheading2}
          </SubHeadlineBox>
        </SectionGroup>
        <FlexRow gap={20} rowGap={0} justifyContent="center">
          <Button cta link={ctaLink}>
            {ctaText}
          </Button>
          <Button ctaSecondary link={secondCtaLink}>
            {secondCtaText}
          </Button>
        </FlexRow>
        <ResponsiveImage
          src={vectors.heroIllustration()}
          origHeight={410}
          origWidth={440}
          title="minware Defeating Development Bottlenecks"
          minWidth={0}
          lrPad={0}
          tbMargin={40}
        />
      </ResponsiveMaxWidthFrame>

      {/* Problem statement: firt-gen metrics are bad */}
      <ResponsiveMaxWidthFrame maxWidth={`${responsiveBreakpoints.multiCol}px`}>
        <ResponsiveMaxWidthFrame maxWidth="640px">
          <HeadingBox
            tagLevel="h2"
            align="center"
            justifyContent="center"
            forceMargin="40px 0 0 0"
          >
            The problem with first-gen metrics
          </HeadingBox>
          <SubHeadlineBox centered>
            First-gen metrics like DORA rely on
            existing data fields (deployment count, PR cycle time, etc.)
            They will get you in the right ballpark, but are:
          </SubHeadlineBox>
        </ResponsiveMaxWidthFrame>
        <SectionGroup showBackground>
          <ResponsiveTwoColumn breakpoint={responsiveBreakpoints.multiCol}>
            <div>
              <HeadingBox
                level="h3"
                align="center"
                justifyContent="center"
                imageUrl={vectors.xIcon(ColorList.orange100)}
                imageSize={16}
                isIcon
              >
                Lagging, unactionable
              </HeadingBox>
              <BodyBox align="center" medium isGray>
                They tell you what happened, but not what caused it.
                Why are cycle times slow – is it interruptions, bad estimates, large
                tickets? How do I get better?
              </BodyBox>
            </div>
            <div>
              <HeadingBox
                level="h3"
                align="center"
                justifyContent="center"
                imageUrl={vectors.xIcon(ColorList.orange100)}
                imageSize={16}
                isIcon
              >
                Disconnected from impact
              </HeadingBox>
              <BodyBox align="center" medium isGray>
                Arbitrary unit counts (tickets, PRs, etc.) don’t tell you what matters.
              </BodyBox>
            </div>
            <div>
              <HeadingBox
                level="h3"
                align="center"
                justifyContent="center"
                imageUrl={vectors.xIcon(ColorList.orange100)}
                imageSize={16}
                isIcon
              >
                Cumbersome and manual
              </HeadingBox>
              <BodyBox align="center" medium isGray>
                If you want additional visibility (work type, tech debt, active dev effort,
                etc.), you have to painstakingly label tickets or log time by hand.
              </BodyBox>
            </div>
          </ResponsiveTwoColumn>
        </SectionGroup>
      </ResponsiveMaxWidthFrame>

      {/* Kevin Quote */}
      <ResponsiveMaxWidthFrame maxWidth="460px" marginTop={60}>
        <FlexRow justifyContent="center" gap={20}>
          <div>
            <SimpleIcon
              image="/img/kevin-photo-medium-2.jpg"
              rounded
              size={80}
            />
          </div>
          <FlexColumn gap={0} alignItems="center" flex={1}>
            <SubHeadlineBox centered isEmphasizedGray>
              “Instead of AI replacing engineers, maybe it should replace tedious non-engineering
              work that wastes their time.”
            </SubHeadlineBox>
            <HeadingBox align="center" color="gray" tagLevel="div" level="h3" noMargin>
              Kevin Borders
            </HeadingBox>
            <BodyBox align="center" noMargin isGray>
              Founder & CEO, minware
            </BodyBox>
          </FlexColumn>
        </FlexRow>
      </ResponsiveMaxWidthFrame>


      {/* Value prop: next-gen models are good */}
      <ResponsiveMaxWidthFrame maxWidth={`${responsiveBreakpoints.multiCol}px`} marginTop={20}>
        <ResponsiveMaxWidthFrame maxWidth="640px">
          <HeadingBox
            tagLevel="h2"
            align="center"
            justifyContent="center"
            forceMargin="40px 0 0 0"
          >
            Next-gen data models offer insights without effort
          </HeadingBox>
          <SubHeadlineBox centered>
            Next-gen data models use modern methods to derive metrics with higher-level meaning
            (e.g., active development time per ticket) that are:
          </SubHeadlineBox>
        </ResponsiveMaxWidthFrame>
        <SectionGroup showBackground>
          <ResponsiveTwoColumn breakpoint={responsiveBreakpoints.multiCol}>
            <div>
              <HeadingBox
                level="h3"
                align="center"
                justifyContent="center"
                imageUrl={vectors.check(ColorList.blue100)}
                imageSize={24}
                isIcon
              >
                Actionable
              </HeadingBox>
              <BodyBox align="center" medium isGray>
                See exactly where problems lie so you know where to improve.
              </BodyBox>
            </div>
            <div>
              <HeadingBox
                level="h3"
                align="center"
                justifyContent="center"
                imageUrl={vectors.target(ColorList.blue100)}
                imageSize={24}
                isIcon
              >
                Impact-Focused
              </HeadingBox>
              <BodyBox align="center" medium isGray>
                Measure the effect on available engineering time instead of arbitrary unit counts.
              </BodyBox>
            </div>
            <div>
              <HeadingBox
                level="h3"
                align="center"
                justifyContent="center"
                imageUrl={vectors.settings(ColorList.blue100)}
                imageSize={24}
                isIcon
              >
                Automatic
              </HeadingBox>
              <BodyBox align="center" medium isGray>
                Compute high-level properties without having to log time, impose mandatory
                fields, or change the way you work.
              </BodyBox>
            </div>
          </ResponsiveTwoColumn>
        </SectionGroup>
        <LinkBox noFlex link="/docs/overview/time-model" size="body" alignCenter>
          Read about minware’s patent-pending data models &gt;
        </LinkBox>
      </ResponsiveMaxWidthFrame>

      {/* Questions minware can answer */}
      <ResponsiveMaxWidthFrame maxWidth={`${responsiveBreakpoints.multiCol}px`}>
        <HeadingBox
          tagLevel="h2"
          align="center"
          justifyContent="center"
          forceMargin="40px 0 10px 0"
        >
          Next-gen data models answer real questions
        </HeadingBox>
        <ResponsiveComparison breakpoint={responsiveBreakpoints.multiCol} evenMarginTop>
          <ResponsiveComparisonText
            leftBarColor={ColorList.blue100}
            breakpoint={responsiveBreakpoints.multiCol}
          >
            <ResponsiveComparisonParagraph>
              CEOs don't ask about cycle times.
              Next-gen data models answer real questions like these (links go to live demo):
            </ResponsiveComparisonParagraph>
            <ResponsiveComparisonParagraph>
              <LinkBox inline link="/docs/demo#leadcycle-time-and-workflow" size="body">
                How can we increase velocity?
              </LinkBox>
            </ResponsiveComparisonParagraph>
            <ResponsiveComparisonParagraph>
              <LinkBox inline link="/docs/demo#individual-contributions" size="body">
                What did we actually work on?
              </LinkBox>
            </ResponsiveComparisonParagraph>
            <ResponsiveComparisonParagraph>
              <LinkBox inline link="/report/project-cost-allocation" size="body">
                ...and is it capitalizable?
              </LinkBox>
            </ResponsiveComparisonParagraph>
            <ResponsiveComparisonParagraph>
              <LinkBox inline link="/docs/demo#cfrbug-creation" size="body">
                How many bugs are we creating, what do they cost us?
              </LinkBox>
            </ResponsiveComparisonParagraph>
            <ResponsiveComparisonParagraph>
              <LinkBox inline link="/docs/demo#higher-level-reports" size="body">
                How good are we at planning?
              </LinkBox>
            </ResponsiveComparisonParagraph>
            <ResponsiveComparisonParagraph>
              <LinkBox inline link="/docs/demo#work-batch-sizes" size="body">
                How large are our work batches?
              </LinkBox>
            </ResponsiveComparisonParagraph>
            <ResponsiveComparisonParagraph>
              <LinkBox inline link="/docs/demo#additional-reports" size="body">
                ...and more (see list)
              </LinkBox>
            </ResponsiveComparisonParagraph>
          </ResponsiveComparisonText>
          <ResponsiveFigure
            maxWidth={1000}
            image="/img/home-agile-big.png"
            aspect={1.4}
            flex={1}
            pad={0}
          />
        </ResponsiveComparison>
      </ResponsiveMaxWidthFrame>

      {/*minQL and BI*/}
      <ResponsiveMaxWidthFrame maxWidth={`${responsiveBreakpoints.multiCol}px`}>
        <HeadingBox
          tagLevel="h2"
          align="center"
          justifyContent="center"
          forceMargin="40px 0 10px 0"
        >
          minQL and BI report builder let you customize anything
        </HeadingBox>
        <ResponsiveComparison breakpoint={responsiveBreakpoints.multiCol} evenMarginTop reverse>
          <ResponsiveComparisonText
            leftBarColor={ColorList.magenta100}
            breakpoint={responsiveBreakpoints.multiCol}
        >
            <ResponsiveComparisonParagraph>
              All minware reports are built on top of the{'  '}
              <LinkBox inline link="/docs/overview/minql" size="body">
                minQL query language
              </LinkBox>{' '}
              and fully editable.
            </ResponsiveComparisonParagraph>
            <ResponsiveComparisonParagraph>
              Access any field from any data source to create custom metrics with powerful formulas,
              including custom event cycle times.
            </ResponsiveComparisonParagraph>
            <ResponsiveComparisonParagraph>
              Say goodbye to spreadsheets and SQL.
            </ResponsiveComparisonParagraph>
            <ResponsiveComparisonParagraph>
              <LinkBox inline noFlex link="/org/demo/dashboard/ae061875-f72c-42a6-b999-ce54d9921df2"
                size="body">See Report Builder Live &gt;</LinkBox>
            </ResponsiveComparisonParagraph>
          </ResponsiveComparisonText>
          <ResponsiveFigure
            maxWidth={1000}
            image="/img/ss-custom-report-ui-orig.png"
            aspect={1.6}
            flex={2.6}
            pad={0}
          />
        </ResponsiveComparison>
      </ResponsiveMaxWidthFrame>

      <ResponsiveMaxWidthFrame maxWidth={`${responsiveBreakpoints.multiCol}px`}>
        <HeadingBox
          tagLevel="h2"
          align="center"
          justifyContent="center"
          forceMargin="40px 0 10px 0"
        >
          Goals and benchmarks make getting better easy
        </HeadingBox>
        <ResponsiveComparison
          breakpoint={responsiveBreakpoints.multiCol}
          evenMarginTop
        >
          <ResponsiveComparisonText
            leftBarColor={ColorList.green100}
            breakpoint={responsiveBreakpoints.multiCol}
          >
            <ResponsiveComparisonParagraph>
              Set goals and track progress toward better process, planning, and CapEx efficiency
              with minware’s library of scorecard metrics.
            </ResponsiveComparisonParagraph>
            <ResponsiveComparisonParagraph>
              Measure code and ticket best practices like using pull requests, thorough code
              reviews, linking branches to tickets, and more.
            </ResponsiveComparisonParagraph>
            <ResponsiveComparisonParagraph>
              <LinkBox inline noFlex link="/org/demo/dashboard/c71ae801-4335-485e-a336-ee2683d3a5bd"
                  size="body">
                See Metric Scorecard Live &gt;
              </LinkBox>
            </ResponsiveComparisonParagraph>
          </ResponsiveComparisonText>
          <ResponsiveFigure
            maxWidth={400}
            image={scorecardGraphic()}
            aspect={1.7}
            pad={0}
            flex={1}
          />
        </ResponsiveComparison>
      </ResponsiveMaxWidthFrame>


      <ResponsiveMaxWidthFrame maxWidth="800px">
        <HeadingBox
          tagLevel="h2"
          align="center"
          justifyContent="center"
          forceMargin="40px 0 10px 0"
        >
          How does it work?
        </HeadingBox>
        <MinwareOctopusStack {...octopusProps} />
      </ResponsiveMaxWidthFrame>

      <ResponsiveMaxWidthFrame maxWidth="800px" spacing={40}>
        <SectionGroup>
          <HeadingBox
            tagLevel="h2"
            align="center"
            justifyContent="center"
            forceMargin="30px 0 10px 0"
          >
            Clean, full-time-series data
          </HeadingBox>
          <SubHeadlineBox centered>
            minware does all the hard work for you – loading data, tracing commit graphs, and
            linking code/tickets to build a full-time-series data set (accessible from anywhere with
            minQL, of course) so you can view your data at any point in the past, and see how each
            person spent their time with auditable precision.
          </SubHeadlineBox>
        </SectionGroup>
      </ResponsiveMaxWidthFrame>

      <ResponsiveMaxWidthFrame maxWidth="600px" spacing={20}>
        <SectionGroup>
          <HeadingBox level="h0" tagLevel="h2" align="center" justifyContent="center">
            Try minware today
          </HeadingBox>
        </SectionGroup>
        <FlexRow gap={20} rowGap={0} justifyContent="center">
          <Button cta link={ctaLink}>
            {ctaText}
          </Button>
          <Button ctaSecondary link={secondCtaLink}>
            {secondCtaText}
          </Button>
        </FlexRow>
      </ResponsiveMaxWidthFrame>
    </Layout>
  )
}

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <IndexPageTemplate
      {...frontmatter}
    />
  )
}

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      ...HeaderMeta
      ...OctopusFragment
      frontmatter {
        title
        subheading
        subheading2
        ctaText
        ctaLink
        secondCtaText
        secondCtaLink
      }
    }
  }
`
