import Analytics from 'analytics';
import googleTagManager from '@analytics/google-tag-manager';
import mixpanelPlugin from '@analytics/mixpanel';
import TabIdCoordinator from './util/TabIdCoordinator';
import { merge } from 'lodash';

const RESOLVE_IP_TIMEOUT = 3_000;

function enrichWithIp(userConfig) {
  let ip = 'Unknown';
  let isLoaded = false;
  /** @type number */
  let ipFetchStart;

  const addIpToProperties = ({ payload }) => {
    if (typeof window === `undefined`) return;

    return merge(payload, {
      properties: {
        userIp: ip
      }
    });
  };

  // return object for analytics to use
  return {
    /* All plugins require a name */
    name: 'enrich-with-ip',
    initialize: () => {
      if (typeof window === `undefined`) return;

      ipFetchStart = Date.now();
      fetch(
          'https://api.minware.com/api/ip',
          { signal: AbortSignal.timeout(5000) }
        )
        .then(response => response.json())
        .then(ipResponse => { ip = ipResponse.ip; })
        .catch(() => {} /** ignored */)
        .finally(() => { isLoaded = true; });
    },
    loaded: () => {
      return isLoaded || (Date.now() - ipFetchStart > RESOLVE_IP_TIMEOUT);
    },
    pageStart: addIpToProperties,
    trackStart: addIpToProperties,
  }
}

function enrichWithPageLoadId(userConfig) {
  let pageLoadId = null;

  const addPageLoadIdToProperties = ({ payload }) => {
    if (typeof window === `undefined`) return;

    return merge(payload, {
      properties: {
        pageLoadId: pageLoadId
      }
    });
  }

  return {
    name: 'enrich-with-page-load-id',
    initialize: () => {
      pageLoadId = getPageLoadId();
    },
    'page:mixpanel': addPageLoadIdToProperties,
    'track:mixpanel': addPageLoadIdToProperties,
  }
}

function enrichWithTabId(userConfig) {
  let tabIdCoordinator = null;

  const addTabIdToProperties = ({ payload }) => {
    if (typeof window === `undefined`) return;

    return merge(payload, {
      properties: {
        tabId: tabIdCoordinator.tabId
      }
    });
  }

  return {
    name: 'enrich-with-tab-id',
    initialize: () => {
      if (typeof window === `undefined`) return;
      tabIdCoordinator = new TabIdCoordinator();
    },
    'page:mixpanel': addTabIdToProperties,
    'track:mixpanel': addTabIdToProperties,
  }
}



const waitForDocumentReadyPlugin = {
  name: 'waitForDocumentReady',
  /* Hook into initializeStart. This is before third party scripts have loaded on the page */
  async initializeStart({ abort, config }) {
    if (
      typeof window !== 'undefined' && typeof document !== 'undefined'
      && document.readyState !== 'complete'
    ) {
      return new Promise(resolve => {
        window.addEventListener('load', () => {
          resolve(true)
        });
      });
    }
    return true;
  },
  loaded() {
    return typeof document !== 'undefined' && document.readyState === 'complete';
  },
};

const analytics = Analytics({
  app: 'minware-www',
  /** enable this to see analytics in the redux devtools */
  //debug: true,
  plugins: [
    waitForDocumentReadyPlugin,
    enrichWithIp(),
    enrichWithPageLoadId(),
    enrichWithTabId(),
    // These are for production - TODO: use different tokens for staging/dev
    googleTagManager({
      containerId: 'GTM-W7VWQDQ',
    }),
    mixpanelPlugin({
      token: '7998a44309da6bee088d922e72d2eef3',
      customScriptSrc: 'https://app.minware.com/prx/mp/lib.min.js',
      options: {
        api_host: 'https://app.minware.com/prx/mp',
        ignore_dnt: true,
      }
    }),
  ],
});

// Set to global so analytics plugin will work with Gatsby
if (typeof window !== 'undefined') {
  window.Analytics = analytics;
}

/**
 * Generate a random page load id for tracking purposes
 */
let pageLoadId = null;
function getPageLoadId() {
  if (pageLoadId) {
    return pageLoadId;
  }
  pageLoadId = `X-${new Date().toISOString()}-${
    Math.floor(Math.random() * 10 ** 16)
  }`;
  return pageLoadId;
}

export function track(...args) {
  analytics.ready(() => {
    analytics.track(...args);
  });
}

export function page(...args) {
  analytics.ready(() => {
    analytics.page(...args);
  });
}


/**
 * Setup page lifecycle tracking
 */
function setupPageLifecycleTracking() {
  if (typeof document === 'undefined') return;

  /**
   * Track page unload
   */
  document.addEventListener('beforeunload', () => {
    track('Page Unload');
  });

  /**
   * Track page visibility change
   */
  document.addEventListener('visibilitychange', () => {
    track(document.visibilityState === 'visible' ? 'Page Became Visible' : 'Page Became Hidden');
  });
}

setupPageLifecycleTracking();

// Export for consumption in your components for .track & .identify calls
export default analytics;
// We need to do this because we wrap our calls in ready and if ready isn't called before the ready
// event is fired the internal state in the analytics packages is not updated
analytics.ready(() => {});
