// This is from https://github.com/KyleAMathews/browser-tab-id/blob/main/src/index.ts
// The package provided on npm does not contain all the correct file for different environments

function localStorageSafeSet(key: string, value: string) {
  try {
    localStorage.setItem(key, value);
  } catch (e) {
    // Do nothing
  }
}

// like jest so we are just adding it here
const LOCK_KEY = `mutexLock`;
const LOCK_TIMEOUT = 100; // 100 milliseconds

export function acquireLock() {
  const now = Date.now();
  const lockValue = localStorage.getItem(LOCK_KEY);

  if (lockValue) {
    const [lockTime, lockTab] = lockValue.split(`|`);

    // If we already have a lock, just continue.
    if (lockTab === getTabId()) {
      return true;
    }
    // Check if the lock has timed out
    if (now - parseInt(lockTime) < LOCK_TIMEOUT) {
      return false; // Lock is still valid
    }
  }

  // Set or update the lock
  localStorageSafeSet(LOCK_KEY, `${now}|${getTabId()}`);
  return true;
}

export function releaseLock() {
  localStorage.removeItem(LOCK_KEY);
}

function getTabId() {
  // Generate a unique identifier for the tab
  if (!localStorage.getItem(`tabId`)) {
    localStorageSafeSet(`tabId`, Date.now() + Math.random().toString());
  }
  return localStorage.getItem(`tabId`);
}

export function runWithLock(fn: Function) {
  const maxDelay = 300; // maximum delay in milliseconds
  const delay = Math.random() * maxDelay; // random delay between 0 to 300 ms

  const attemptLock = () => {
    if (acquireLock()) {
      fn();

      releaseLock();
    } else {
      setTimeout(attemptLock, delay);
    }
  };

  attemptLock();
}

interface TabIdEntry {
  lastUpdate: number;
  tabId: number;
}

class TabIdCoordinator {
  /**
   * TabIdCoordinator needs to only have one instance otherwise the storage event listeners will
   * loop of each other endlessly
   */
  private static instance: TabIdCoordinator | undefined;

  static getInstance() {
    if (!this.instance) {
      this.instance = new TabIdCoordinator();
    }
    return this.instance;
  }

  private heartbeatInterval: number;

  private inactivityThreshold: number;

  private tabIDsKey: string;

  public tabId: number;

  private uuid: string;

  private sessionIDKey: string;

  private constructor() {
    this.heartbeatInterval = 10000;
    this.inactivityThreshold = 5 * 1000 * 60; // 5 minutes
    this.tabIDsKey = 'tab_ids';
    this.sessionIDKey = 'tab_coordinator_id'; // Key for storing ID in sessionStorage
    this.uuid = this.generateUUID();
    this.tabId = this.retrieveSessionId(); // Attempt to retrieve tabId from sessionStorage
    runWithLock(() => {
      this.assignTabId(this.getAndCleanupIds());
    });
    setInterval(() => this.updateHeartbeat(), this.heartbeatInterval);
    // Handle unload event to cleanup our own id
    window.addEventListener('unload', () => {
      this.setTabIDs(this.getAndCleanupIds(true));
    });

    // When the document becomes visible again update the state
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'visible') {
        this.updateHeartbeat();
      }
    });
  }

  private generateUUID() {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
  }

  private retrieveSessionId() {
    // Retrieve the existing tabId from sessionStorage if it exists
    return parseInt(sessionStorage.getItem(this.sessionIDKey) || ``);
  }

  private storeSessionID(tabId: number) {
    // Store the assigned ID in sessionStorage
    sessionStorage.setItem(this.sessionIDKey, tabId.toString());
  }

  private getTabIDs(): Record<string, TabIdEntry> {
    return JSON.parse(localStorage.getItem(this.tabIDsKey) || '{}');
  }

  private setTabIDs(ids: Record<string, TabIdEntry>) {
    localStorageSafeSet(this.tabIDsKey, JSON.stringify(ids));
  }

  /**
   * Using the lock, cleanup the ids object and update our on time
   */
  private updateHeartbeat() {
    runWithLock(() => {
      const ids = this.getAndCleanupIds(); // Clean up on each heartbeat

      if (this.uuid in ids) {
        ids[this.uuid].lastUpdate = Date.now();
      } else {
        ids[this.uuid] = {
          lastUpdate: Date.now(),
          tabId: this.findLowestAvailableID(ids),
        };
      }
      this.setTabIDs(ids);
    });
  }

  private findLowestAvailableID(ids: Record<string, TabIdEntry>) {
    const usedIds = Object.values(ids).map(entry => entry.tabId);
    let tabId = 1;
    while (usedIds.includes(tabId)) {
      tabId++;
    }
    return tabId;
  }

  /**
   * Retrieves ids from localstorage and cleans up the ids, removing anything considered inactive
   */
  private getAndCleanupIds(removeSelf = false) {
    const ids = this.getTabIDs();
    const now = Date.now();
    for (const [uuid, data] of Object.entries(ids)) {
      if (now - data.lastUpdate > this.inactivityThreshold) {
        delete ids[uuid];
      }
    }
    // This will happen on the page unload
    if (removeSelf) {
      delete ids[this.uuid];
    }
    return ids;
  }

  /**
   * Assigne the current tab an id or re-use the existing one
   */
  private assignTabId(ids: Record<string, TabIdEntry>) {
    if (this.tabId) {
      // If the tab has an ID from sessionStorage, reuse it
      ids[this.uuid] = { lastUpdate: Date.now(), tabId: this.tabId };
    } else {
      // If no ID in sessionStorage, find a new ID
      this.tabId = this.findLowestAvailableID(ids);
      ids[this.uuid] = { lastUpdate: Date.now(), tabId: this.tabId };
      this.storeSessionID(this.tabId); // Store the new tabId in sessionStorage
    }
    this.setTabIDs(ids);
  }
}

export default TabIdCoordinator;
