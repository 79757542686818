import React, { useState, useCallback, useRef, useEffect } from 'react';
import PropTypes, { object } from 'prop-types';
import { graphql } from 'gatsby';
import { navigate } from 'gatsby-link'
import Layout from '../components/Layout';
import HeaderMeta from '../components/HeaderMeta';

import {
  ResponsiveMaxWidthFrame,
  SectionGroup,
  TitleBox,
  SubHeadlineBox,
  EmailSubmitForm,
  HeadingBox,
  ResponsiveComparison,
  ResponsiveComparisonText,
  ResponsiveComparisonParagraph,
  LinkBox,
  ComparisonTable,
  FlexColumn,
  ResponsiveImage,
  ResponsiveFigure,
  Button,
  FlexRow,
  responsiveBreakpoints,
  ResponsiveTwoColumn,
  vectors,
  ColorList,
  BodyBox,
  SimpleIcon,
} from "@minware/ui-minware";
import { track } from '../analytics';

export const ComparisonPageTemplate = ({
  content,
  pathname,
  comfirmDemoPage,
  handleSubmit,
  handleChange,
  handleFocus,
  handleBlur
}) => {
  const headerWithCanon = {
    ...content,
    canonicalUrl: pathname,
    title: content.title,
  };

  const {
    heading,
    subHeading,
    firstGenText,
    disconnectedText,
    minqlText,
    competitorName,
    sections,
    footer,
  } = content;

  return (
    <Layout desktopBgImageName="homeDesktop">
      <HeaderMeta header={headerWithCanon}/>
      <ResponsiveMaxWidthFrame maxWidth="480px" marginBottom={60}>
        <SectionGroup>
          <TitleBox>{heading}</TitleBox>
          {subHeading.map(subHeadString => (
            <SubHeadlineBox centered>
              {subHeadString}
            </SubHeadlineBox>
          ))}
        </SectionGroup>
        <EmailSubmitForm
          buttonLabel="Email/Talk to Us"
          name="demo"
          method="post"
          action={comfirmDemoPage}
          data-netlify="true"
          data-netlify-honeypot="bot-field"
          onSubmit={(e) => handleSubmit(e, 'comparisonTop')}
          onEmailChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        >
          <input type="hidden" name="form-name" value="demo" />
          <input type="hidden" name="location" value="comparison" />
          <input type="hidden" name="page" value={pathname} />
          <div hidden>
            <label>
              Don't fill this out:{' '}
              <input name="bot-field" onChange={handleChange} />
            </label>
          </div>
        </EmailSubmitForm>

        <FlexRow alignItems="center">
          <Button ctaSecondary link="/docs/demo">
            Self-Guided Demo
          </Button>
        </FlexRow>

      </ResponsiveMaxWidthFrame>

      {/* Problem statement: firt-gen metrics are bad */}
      <ResponsiveMaxWidthFrame maxWidth={`${responsiveBreakpoints.multiCol}px`}>
        <ResponsiveMaxWidthFrame maxWidth="640px">
          <HeadingBox
            tagLevel="h2"
            align="center"
            justifyContent="center"
            forceMargin="40px 0 0 0"
          >
            The problem with first-gen metrics
          </HeadingBox>
          <SubHeadlineBox centered>
            {firstGenText
            || `${competitorName}’s first-gen metrics rely on existing data fields (PR cycle time, deployment count, etc.) They will get you in the right ballpark, but are:`}
          </SubHeadlineBox>
        </ResponsiveMaxWidthFrame>
        <SectionGroup showBackground>
          <ResponsiveTwoColumn breakpoint={responsiveBreakpoints.multiCol}>
            <div>
              <HeadingBox
                level="h3"
                align="center"
                justifyContent="center"
                imageUrl={vectors.xIcon(ColorList.orange100)}
                imageSize={16}
                isIcon
              >
                Lagging, unactionable
              </HeadingBox>
              <BodyBox align="center" medium isGray>
                They tell you what happened, but not what caused it.
                Why are cycle times slow – is it interruptions, bad estimates, large
                tickets? How do I get better?
              </BodyBox>
            </div>
            <div>
              <HeadingBox
                level="h3"
                align="center"
                justifyContent="center"
                imageUrl={vectors.xIcon(ColorList.orange100)}
                imageSize={16}
                isIcon
              >
                Disconnected from impact
              </HeadingBox>
              <BodyBox align="center" medium isGray>
                {disconnectedText
                || `${competitorName}’s metrics use arbitrary unit counts (tickets, PRs, etc.), which don’t tell you what matters.`}
              </BodyBox>
            </div>
            <div>
              <HeadingBox
                level="h3"
                align="center"
                justifyContent="center"
                imageUrl={vectors.xIcon(ColorList.orange100)}
                imageSize={16}
                isIcon
              >
                Cumbersome and manual
              </HeadingBox>
              <BodyBox align="center" medium isGray>
                If you want additional visibility (work type, tech debt, active dev effort,
                etc.), you have to painstakingly label tickets or log time by hand.
              </BodyBox>
            </div>
          </ResponsiveTwoColumn>
        </SectionGroup>
      </ResponsiveMaxWidthFrame>

      {/* Kevin Quote */}
      <ResponsiveMaxWidthFrame maxWidth="460px" marginTop={60}>
        <FlexRow justifyContent="center" gap={20}>
          <div>
            <SimpleIcon
              image="/img/kevin-photo-medium-2.jpg"
              rounded
              size={80}
            />
          </div>
          <FlexColumn gap={0} alignItems="center" flex={1}>
            <SubHeadlineBox centered isEmphasizedGray>
              “Instead of AI replacing engineers, maybe it should replace tedious non-engineering
              work that wastes their time.”
            </SubHeadlineBox>
            <HeadingBox align="center" color="gray" tagLevel="div" level="h3" noMargin>
              Kevin Borders
            </HeadingBox>
            <BodyBox align="center" noMargin isGray>
              Founder & CEO, minware
            </BodyBox>
          </FlexColumn>
        </FlexRow>
      </ResponsiveMaxWidthFrame>

      {/* Value prop: next-gen models are good */}
      <ResponsiveMaxWidthFrame maxWidth={`${responsiveBreakpoints.multiCol}px`} marginTop={20}>
        <ResponsiveMaxWidthFrame maxWidth="640px">
          <HeadingBox
            tagLevel="h2"
            align="center"
            justifyContent="center"
            forceMargin="40px 0 0 0"
          >
            Next-gen data models offer insights without effort
          </HeadingBox>
          <SubHeadlineBox centered>
            Next-gen data models use modern methods to derive metrics with higher-level meaning
            (e.g., active development time per ticket) that are:
          </SubHeadlineBox>
        </ResponsiveMaxWidthFrame>
        <SectionGroup showBackground>
          <ResponsiveTwoColumn breakpoint={responsiveBreakpoints.multiCol}>
            <div>
              <HeadingBox
                level="h3"
                align="center"
                justifyContent="center"
                imageUrl={vectors.check(ColorList.blue100)}
                imageSize={24}
                isIcon
              >
                Actionable
              </HeadingBox>
              <BodyBox align="center" medium isGray>
                See exactly where problems lie so you know where to improve.
              </BodyBox>
            </div>
            <div>
              <HeadingBox
                level="h3"
                align="center"
                justifyContent="center"
                imageUrl={vectors.target(ColorList.blue100)}
                imageSize={24}
                isIcon
              >
                Impact-Focused
              </HeadingBox>
              <BodyBox align="center" medium isGray>
                Measure the effect on available engineering time instead of arbitrary unit counts.
              </BodyBox>
            </div>
            <div>
              <HeadingBox
                level="h3"
                align="center"
                justifyContent="center"
                imageUrl={vectors.settings(ColorList.blue100)}
                imageSize={24}
                isIcon
              >
                Automatic
              </HeadingBox>
              <BodyBox align="center" medium isGray>
                Compute high-level properties without having to log time, impose mandatory
                fields, or change the way you work.
              </BodyBox>
            </div>
          </ResponsiveTwoColumn>
        </SectionGroup>
        <LinkBox noFlex link="/docs/overview/time-model" size="body" alignCenter>
          Read about minware’s patent-pending data models &gt;
        </LinkBox>
      </ResponsiveMaxWidthFrame>

      {/* Questions minware can answer */}
      <ResponsiveMaxWidthFrame maxWidth={`${responsiveBreakpoints.multiCol}px`}>
        <HeadingBox
          tagLevel="h2"
          align="center"
          justifyContent="center"
          forceMargin="40px 0 10px 0"
        >
          Next-gen data models answer real questions
        </HeadingBox>
        <ResponsiveComparison breakpoint={responsiveBreakpoints.multiCol} evenMarginTop>
          <ResponsiveComparisonText
            leftBarColor={ColorList.blue100}
            breakpoint={responsiveBreakpoints.multiCol}
          >
            <ResponsiveComparisonParagraph>
              CEOs don't ask about {competitorName} metrics like cycle times.
              Next-gen data models answer real questions like these (links go to live demo):
            </ResponsiveComparisonParagraph>
            <ResponsiveComparisonParagraph>
              <LinkBox inline link="/docs/demo#leadcycle-time-and-workflow" size="body">
                How can we increase velocity?
              </LinkBox>
            </ResponsiveComparisonParagraph>
            <ResponsiveComparisonParagraph>
              <LinkBox inline link="/docs/demo#individual-contributions" size="body">
                What did we actually work on?
              </LinkBox>
            </ResponsiveComparisonParagraph>
            <ResponsiveComparisonParagraph>
              <LinkBox inline link="/report/project-cost-allocation" size="body">
                ...and is it capitalizable?
              </LinkBox>
            </ResponsiveComparisonParagraph>
            <ResponsiveComparisonParagraph>
              <LinkBox inline link="/docs/demo#cfrbug-creation" size="body">
                How many bugs are we creating, what do they cost us?
              </LinkBox>
            </ResponsiveComparisonParagraph>
            <ResponsiveComparisonParagraph>
              <LinkBox inline link="/docs/demo#higher-level-reports" size="body">
                How good are we at planning?
              </LinkBox>
            </ResponsiveComparisonParagraph>
            <ResponsiveComparisonParagraph>
              <LinkBox inline link="/docs/demo#work-batch-sizes" size="body">
                How large are our work batches?
              </LinkBox>
            </ResponsiveComparisonParagraph>
            <ResponsiveComparisonParagraph>
              <LinkBox inline link="/docs/demo#additional-reports" size="body">
                ...and more (see list)
              </LinkBox>
            </ResponsiveComparisonParagraph>
          </ResponsiveComparisonText>
          <ResponsiveFigure
            maxWidth={1000}
            image="/img/home-agile-big.png"
            aspect={1.4}
            flex={1}
            pad={0}
          />
        </ResponsiveComparison>
      </ResponsiveMaxWidthFrame>

      {/*minQL and BI*/}
      <ResponsiveMaxWidthFrame maxWidth={`${responsiveBreakpoints.multiCol}px`}>
        <HeadingBox
          tagLevel="h2"
          align="center"
          justifyContent="center"
          forceMargin="40px 0 10px 0"
        >
          minQL and BI report builder let you customize anything
        </HeadingBox>
        <ResponsiveComparison breakpoint={responsiveBreakpoints.multiCol} evenMarginTop reverse>
          <ResponsiveComparisonText
            leftBarColor={ColorList.magenta100}
            breakpoint={responsiveBreakpoints.multiCol}
        >
            <ResponsiveComparisonParagraph>
              {minqlText}
            </ResponsiveComparisonParagraph>
            <ResponsiveComparisonParagraph>
              All minware reports are built on top of the{'  '}
              <LinkBox inline link="/docs/overview/minql" size="body">
                minQL query language
              </LinkBox>{' '}
              and fully editable.
              Access any field from any data source to create custom metrics with powerful formulas,
              including custom event cycle times.
            </ResponsiveComparisonParagraph>
            <ResponsiveComparisonParagraph>
              Say goodbye to spreadsheets and SQL.
            </ResponsiveComparisonParagraph>
            <ResponsiveComparisonParagraph>
              <LinkBox inline noFlex link="/org/demo/dashboard/ae061875-f72c-42a6-b999-ce54d9921df2"
                size="body">See Report Builder Live &gt;</LinkBox>
            </ResponsiveComparisonParagraph>
          </ResponsiveComparisonText>
          <ResponsiveFigure
            maxWidth={1000}
            image="/img/ss-custom-report-ui-orig.png"
            aspect={1.6}
            flex={2.2}
            pad={0}
          />
        </ResponsiveComparison>
      </ResponsiveMaxWidthFrame>

      {sections.map(({
        heading,
        subHeading,
        link,
        linkText,
        tableWidth,
        comparisonHeading,
        comparisonRows,
        goalMessage,
        showFunnelArrows,
        circleComparisonText,
        figureMaxWidth,
        figureImage,
        figureAspect,
        figureFlex,
      }, sectionIndex) => (
        <ResponsiveMaxWidthFrame maxWidth={`${responsiveBreakpoints.multiCol}px`} justifyContent="center">
          <HeadingBox
            tagLevel="h2"
            align="center"
            justifyContent="center"
            forceMargin="40px 0 10px 0"
          >
            {heading}
          </HeadingBox>
          <ResponsiveComparison
            breakpoint={responsiveBreakpoints.multiCol}
            reverse={sectionIndex % 2 === 1}
          >
            <ResponsiveComparisonText
              leftBarColor={sectionIndex === 0 ? ColorList.green100 : ColorList.purple100}
              breakpoint={responsiveBreakpoints.multiCol}
            >
              {subHeading.map(subHeadString => (
                <ResponsiveComparisonParagraph>
                  {subHeadString}
                </ResponsiveComparisonParagraph>
              ))}
              {link ? (
                <ResponsiveComparisonParagraph>
                  <LinkBox link={link} inline noFlex size="body">{linkText}</LinkBox>
                </ResponsiveComparisonParagraph>
              ) : null}
            </ResponsiveComparisonText>
            {circleComparisonText ? (
              <ResponsiveImage
                src="/img/competitor-reports.png"
                alt="Competitor report coverage"
                origWidth={640}
                origHeight={640}
                maxWidth={360}
                minWidth={320}
                lrPad={20}
                overlayText={circleComparisonText}
                overlayBox={{
                  top: 174 * 2,
                  left: 115 * 2,
                  width: 90 * 2,
                  height: 90 * 2,
                }}
                fontSize={16}
              />
            ) : figureImage ? (
                <ResponsiveFigure
                  maxWidth={figureMaxWidth}
                  image={figureImage.substr(1) /* hack to avoid treating as image */}
                  aspect={figureAspect}
                  flex={figureFlex}
                  pad={0}
                />
            ) : (
              <ComparisonTable
                heading={comparisonHeading}
                rows={comparisonRows}
                goalMessage={goalMessage}
                showFunnelArrows={showFunnelArrows}
                maxWidth={tableWidth}
              />
            )}
          </ResponsiveComparison>
        </ResponsiveMaxWidthFrame>
      ))}
      <ResponsiveMaxWidthFrame maxWidth="480px" justifyContent="center">
        <SectionGroup>
          <HeadingBox
            tagLevel="h2"
            align="center"
            justifyContent="center"
            forceMargin="20px 0 10px 0"
          >
            {footer.heading}
          </HeadingBox>
          {footer.subHeading.map(subHeadString => (
            <SubHeadlineBox centered>
              {subHeadString}
            </SubHeadlineBox>
          ))}
        </SectionGroup>
        <FlexColumn alignItems="center">
          <EmailSubmitForm
            buttonLabel={footer.buttonCopy}
            name="demo"
            method="post"
            action={comfirmDemoPage}
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            onSubmit={(e) => handleSubmit(e, 'comparisonBottom')}
            onEmailChange={handleChange}
            handleFocus={handleFocus}
            handleBlur={handleBlur}
          >
            {/* The `form-name` hidden field is required to support form submissions without
                JavaScript */}
            <input type="hidden" name="location" value="comparison" />
            <input type="hidden" name="page" value={pathname} />
            <div hidden>
              <label>
                Don't fill this out:{' '}
                <input name="bot-field" onChange={handleChange} />
              </label>
            </div>
          </EmailSubmitForm>

          <FlexRow alignItems="center">
            <Button ctaSecondary link="/docs/demo">
              Self-Guided Demo
            </Button>
          </FlexRow>
        </FlexColumn>
      </ResponsiveMaxWidthFrame>

    </Layout>
  )
}

ComparisonPageTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const ComparisonPage = ({ data, location }) => {
  const { post } = data;
  const { pathname } = location;
  const stripSlashPath = pathname.length > 0 && pathname[pathname.length - 1] === '/'
    ? pathname.substr(0, pathname.length - 1)
    : pathname;
  const content = post.frontmatter;

  const [formState, setFormState] = useState({});

  const timeoutRef = useRef();

  // Track changes to the form state after 500ms of no change
  useEffect(() => {
    if (Object.keys(formState).length) {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => {
        timeoutRef.current = null;
        track('Comparison Page - Form Change', {
          formState
        })
      }, 500);
    }
  }, [formState]);

  const handleFocus = useCallback((e) => {
    track(`Comparison Page - Form Focus - ${e.target.name}`, {
      value: e.target.value,
    });
  }, []);

  const handleBlur = useCallback((e) => {
    track(`Comparison Page - Form Blur - ${e.target.name}`, {
      value: e.target.value,
    });
  }, []);

  const handleChange = useCallback((e) => {
    const formData = new FormData(e.target.form);
    setFormState(Object.fromEntries(formData.entries()));
  }, []);

  const confirmDemoPage = '/contact/thanks-demo/';

  const handleSubmit = (e) => {
    const formData = new FormData(e.target)
    track('Comparison Page - Form Submit', {
      formState: Object.fromEntries(formData.entries()),
    });
    e.preventDefault()
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => navigate(confirmDemoPage))
      .catch((error) => alert(error))
  }

  return (
    <ComparisonPageTemplate
      content={content}
      post={post}
      pathname={stripSlashPath}
      comfirmDemoPage={confirmDemoPage}
      handleSubmit={handleSubmit}
      handleChange={handleChange}
      handleFocus={handleFocus}
      handleBlur={handleBlur}
    />
  )
}

ComparisonPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default ComparisonPage

export const pageQuery = graphql`
  query ComparisonPageByID($id: String!) {
    post: markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        description
        heading
        subHeading
        firstGenText
        disconnectedText
        minqlText
        competitorName
        sections {
          heading
          subHeading
          tableWidth
          comparisonHeading {
            leftHeading
            competitorName
          }
          comparisonRows {
            heading
            leftCheck
            rightCheck
          }
        }
        footer {
          heading
          subHeading
          buttonCopy
        }
      }
    }
  }
`
